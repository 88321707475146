import $ from 'jquery';
import { createPopper } from '@popperjs/core';

$(function () {
  const $viewOverlay = $('.room-page__view-overlay');
  const $viewItem = $('.js-view-item');
  const isMobile = !window.matchMedia("(min-width: 768px)").matches;
  const isDesktop = window.matchMedia("(min-width: 1025px)").matches;
  let viewOverlayModClass = '';

  if (isDesktop) {
    addListener('mouseenter', $viewItem, onMouseEnterOrClick);
    addListener('mouseleave', $viewItem, onMouseLeave);
  } else {
    addListener('click', $viewItem, onMouseEnterOrClick);
  }

  function addListener(eventName = '', elem = null, cb = function () { }) {
    elem.on(eventName, cb)
  }

  function onMouseEnterOrClick() {
    const _this = $(this);
    const data = _this.data();
    const { objectName, tooltipLinks } = data;
    const tooltipWrap = createTooltipHtml(tooltipLinks);

    // Если десктоп или планшет то создаем инстанс Popper
    if (!isMobile) createPopperTooltip(_this[0], tooltipWrap[0], $viewOverlay[0]);

    $viewItem.not(this).css('pointer-events', 'none');
    viewOverlayModClass = `room-page__view-overlay_${objectName} room-page__view-overlay_mask`;
    $viewOverlay.addClass(viewOverlayModClass);
    $viewOverlay.css('opacity', '1');

    // Если десктоп то добавляем тултип в элемент
    isDesktop ? _this.append(tooltipWrap) : $viewOverlay.append(tooltipWrap);
  }

  function onMouseLeave() {
    $viewOverlay.find('.room-page__tooltip-wrap').remove();
    $viewOverlay.removeClass(viewOverlayModClass);
    $viewOverlay.css('opacity', '0');
    $viewItem.not(this).css('pointer-events', 'all');
  }

  function createPopperTooltip(container, tooltip, boundary) {
    createPopper(container, tooltip, {
      modifiers: [
        {
          name: 'flip',
          options: {
            boundary,
            rootBoundary: boundary,
          },
        },
      ],
    });
  }

  function createTooltipHtml(links) {
    const $tooltipWrap = $('<div>', { class: 'room-page__tooltip-wrap' });
    const $linkList = $('<ul>', { class: 'room-page__tooltip-list' });
    const $closeButton = $('<button>', { class: 'room-page__tooltip-close' });

    $.each(links, function (_, item) {
      $linkList.append(`
        <li class="room-page__tooltip-item">
          <a class="room-page__tooltip-link" href="${item.href}">
            ${item.text}
          </a>
        </li>`
      );
    });

    $tooltipWrap.append($closeButton);
    $tooltipWrap.append($linkList);

    $closeButton.on('click', function () {
      $tooltipWrap.remove();
      $viewOverlay.removeClass(viewOverlayModClass);
      $viewItem.css('pointer-events', 'auto');
      $viewOverlay.css('opacity', '0');
    })

    return $tooltipWrap;
  }
})

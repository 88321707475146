import $ from 'jquery';

class Filter {
  constructor() {
    this.$filtersEl = $('.filters');
    this.$clickToggleEl = $('.js-toggle-list');
    this.$closeButton = $('.js-close-filter-modal');
    this.$elForSelectSubfilter = $('.js-select-subfilters');
    this.$elForSelectFilter = $('.js-select-filter');
    this.$filtersCheckbox = $('.checkbox__input', this.$filtersEl);
    this.filtersCount = this.$filtersCheckbox.length;
    this.isDesktop = window.matchMedia("(min-width: 1025px)").matches;
    this.$clearButton = $('.js-clear-filters');
    this.$showButton = $('.js-show-filters');
    this.$applyButton = $('.js-apply-filters');

    this.addListeners();
  }

  // Добавляем слушатель событий на элементы

  addListeners() {
    this.$clickToggleEl.on('click', this.toggleFiltersList);
    this.$closeButton.on('click', () => {
      return this.closeFiltersModal();
    });
    this.$elForSelectSubfilter.on('change', this.toggleSelectSubfilters);
    this.$elForSelectFilter.on('click', () => {
      return this.toggleSelectFilter();
    });
    this.$filtersCheckbox.on('change', this.watchSelectFilter);
    this.$filtersCheckbox.on('change', () => {
      this.toggleDisableApplyButton();
    });

    this.$clearButton.on('click', () => {
      return this.clearFilters();
    });
    this.$applyButton.on('click', () => {
      return this.applyFilters();
    });
    this.$showButton.on('click', () => {
      return this.showModalFilters();
    });

    $(window).on('resize', () => {
      if (this.isDesktop) this.showModalFilters();
    });
  };

  // Раскрываем или сворачиваем список фильтров

  toggleFiltersList() {
    const _this = $(this);
    const $filterList = _this.siblings('ul.filters__list');
    const isOpeningList = _this.hasClass('_opened');
    _this.toggleClass('_opened');
    isOpeningList ? Filter.slideUpFilterList($filterList) : Filter.slideDownFilterList($filterList);
  };

  // Закрытие модалки с фильтрами

  closeFiltersModal() {
    this.$filtersEl.fadeOut(300);
  };

  // Очищает фильтры

  clearFilters() {
      window.location = "/catalog-page";
  }

  // Показать фильтры

  showModalFilters() {
    this.$filtersEl.fadeIn(300);
  }

  // Применение фильтров

  applyFilters() {
    const $filtersSelected = $('.checkbox__input:checked', this.$filtersEl);
    const filtersData = this.generateFiltersDataForRequest($filtersSelected);
    window.location = "/catalog-page/filters?" + $.param(filtersData);
  }

  // Собирает данные для отправки на сервер

  generateFiltersDataForRequest($checkedFilters) {
    const data = [];

    $.each($checkedFilters, function () {
      const _this = $(this);
      const value = _this.val();
      const name = _this.attr('name');
      const filterObject = {
        value,
        name,
      }

      data.push(filterObject);
    })

    return data;
  }

  /**
   * Метод отслеживает изменения выбора подфильтров,
   * если выбраны все подфильтры, то выделяется родительский фильтр
   * также работает и в обратную сторону
  **/

  watchSelectFilter() {
    const _this = $(this);
    const isSelectSubfilter = _this.hasClass('js-select-subfilters');

    if (isSelectSubfilter) return;

    const $listSubfilters = _this.parents('ul.filters__list_level-2');
    const $mainFilterWrapper = $listSubfilters.siblings('.filters__item-checkbox');
    const $mainFilter = $mainFilterWrapper.find('.checkbox__input');
    const countSubfilters = $listSubfilters.find('.checkbox__input').length;
    const countSelectedFilters = $listSubfilters.find('.checkbox__input:checked').length;

    if (countSubfilters === countSelectedFilters) {
      $mainFilter.prop('checked', true);
    } else {
      $mainFilter.prop('checked', false);
    }
  };

  // Отмечает или отменяет выбор подфильтров, при отметке главного фильтра.

  toggleSelectSubfilters() {
    const _this = $(this);
    const isChecked = _this.prop('checked');

    const $listWithSubfilters = _this.parents('.filters__item-checkbox').siblings('ul.filters__list');
    const $subFilters = $('.checkbox__input', $listWithSubfilters);

    $.each($subFilters, (_, subfilter) => {
      $(subfilter).prop('checked', isChecked);
    })
  };

  toggleSelectFilter() {
    const _this = $(this);
    const $filter = $('.checkbox__input', _this);
    const isCheckedFilter = $filter.prop('checked');

    _this.toggleClass('filters__item_selected');
    $filter.prop('checked', !isCheckedFilter);
  }

  // Раскрытие списка фильтров.

  static slideDownFilterList($filterList) {
    $filterList.slideDown();
  };

  // Сворачивание списка фильтров.

  static slideUpFilterList($filterList) {
    $filterList.slideUp();
  };

  // Блокирует или делает доступной кнопку для применения фильтров

  toggleDisableApplyButton() {
    const $filtersSelectedCount = $('.checkbox__input:checked', this.$filtersEl).length;

    if ($filtersSelectedCount > 0) {
      this.$applyButton.prop('disabled', false);
      this.$applyButton.removeClass('button_disabled');
      return;
    }

    this.$applyButton.prop('disabled', true);
    this.$applyButton.addClass('button_disabled');
  };
}

export default new Filter();

import $ from 'jquery';

class Nav {

    constructor() {

        this.KEY_CODES = {
            escape: 27
        };

        this.nav = $('#js-nav');
        this.firstItem = this.nav.find('.nav__item-first');
        this.dropdown = this.nav.find('.nav__sublist');
        this.burger = document.getElementById("js-burger");
        this.body = document.body;
        this.header = document.getElementById("header");

        this.onClickBurger();
        this.onMouserEnterFirstItem();
        this.onMouserLeaveFirstItem();
        this.onFocusNav();
        this.onKeyDownFirstItem();

    }

    onClickBurger() {
        this.burger.addEventListener('click', () => {
            this.isOpen ? this.close() : this.open();
        });
    }

    open() {
        this.body.classList.add('scrolling-disable');
        this.burger.classList.add('is-active');
        this.nav.addClass('nav_active');
        this.header.classList.add('header_black');
        this.isOpen = true;
    }

    close() {
        this.body.classList.remove('scrolling-disable');
        this.burger.classList.remove('is-active');
        this.nav.removeClass('nav_active');
        this.header.classList.remove('header_black');
        this.isOpen = false;
    }
    /**
     * Открывает выпадающую навигацию.
     * @param firstItem — jQuery object родительского пункта навигации.
     */
    openDropdown(firstItem) {

        const item = firstItem;
        const link = item.find('> a');
        const dropdown = firstItem.find('.nav__sublist');

        if (this.isOpen === undefined) {
            dropdown.show();

            item.addClass('nav__item-first_active');

            link.attr('aria-expanded', true);
            dropdown.attr('aria-expanded', true);
        }
    }

    /**
     * Закрывает выпадающую навигацию.
     * @param firstItem — jQuery object родительского пункта навигации.
     */
    closeDropdown(firstItem) {

        const item = firstItem;
        const link = item.find('> a');
        const dropdown = item.find('.nav__sublist');

        dropdown.hide();

        item.removeClass('nav__item-first_active');

        link.attr('aria-expanded', false);
        dropdown.attr('aria-expanded', false);

    }

    closeDropdownAll() {
        this.dropdown.hide();
        this.firstItem.removeClass('nav__item-first_active');
        this.firstItem.find('> a').attr('aria-expanded', false);
        this.dropdown.attr('aria-expanded', false);
    }

    /**
     * Обработчик mouseenter.
     */
    onMouserEnterFirstItem() {

        this.firstItem.on('mouseenter', (event) => {
            let item = $(event.target).closest('.nav__item-first');
            this.openDropdown(item);
        });

    }

    /**
     * Обработчик mouseleave.
     */
    onMouserLeaveFirstItem() {

        this.firstItem.on('mouseleave', (event) => {
            let item = $(event.target).closest('.nav__item-first');
            this.closeDropdown(item);
        });

    }

    /**
     * Обработчик фокуса на навигации.
     */
    onFocusNav() {

        $(document).on('focusin', (event) => {

            if ( $(event.target).closest('.nav').length ) {

                let currentItem = $(event.target).closest('.nav__item-first');

                this.closeDropdownAll();
                this.openDropdown(currentItem);

                return false;
            }

            this.closeDropdownAll();
        });
    }

    /**
     * Обработчик нажатия клавиши на пункте меню.
     */
    onKeyDownFirstItem() {

        this.firstItem.on('keydown', (event) => {

            const keyCode = event.keyCode;

            // При нажатии Escape закрываем все выпадающие подменю
            if (keyCode === this.KEY_CODES.escape) {
                this.closeDropdownAll();
            }

        });

    }
}

export default new Nav();
